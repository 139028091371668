import { createRouter, createWebHashHistory } from "vue-router";

const Price = () => import("@/components/Price");
const Stocks = () => import("@/components/Stocks");
const PriceRecordInsect = () => import("@/components/PriceRecordInsect");
const Order = () => import("@/components/Order");
const Login = () => import("@/components/Login");
const PriceRecord = () => import("@/components/PriceRecord");
const ThreeList = () => import("@/components/ThreeList");
const UniversalData = () => import("@/components/UniversalData");
const InsectLable = () => import("@/components/InsectLable");
const GoodOrder = () => import("@/components/GoodOrder");
const UserData = () => import("@/components/UserData");
const ImgGallery = () => import("@/components/ImgGallery");
const ImgGalleryNew = () => import("@/components/ImgGalleryNew");
const ImgGalleryKilala = () => import("@/components/ImgGalleryKilala");
const DownLoad = () => import("@/components/DownLoad");
const TagLable = () => import("@/components/TagLable");
const MonoPhotos = () => import("@/components/MonoPhotos");
const PdfDetail = () => import("@/components/PdfDetail");
const BookList = () => import("@/components/BookList");
const PdfList = () => import("@/components/PdfList");
const InsectSpecies = () => import("@/components/InsectSpecies");
const LiquidSpecimen = () => import("@/components/LiquidSpecimen");
const DetailPage = () => import("@/components/DetailPage");
const CardLabel = () => import("@/components/CardLabel");
const CardLabelBeast = () => import("@/components/CardLabelBeast");
const CardLabelFish = () => import("@/components/CardLabelFish");
const LayoutDesign = () => import("@/components/LayoutDesign");
const PrintPage = () => import("@/components/PrintPage");
const SelectSpecimen = () => import("@/components/SelectSpecimen");
const HandBook = () => import("@/components/HandBook");
const CardLabelMedicine = () => import("@/components/CardLabelMedicine");
const ThreeData = () => import("@/components/ThreeData");
const DeletedData = () => import("@/components/DeletedData");
const CardLabelButterfly = () => import("@/components/CardLabelButterfly");
const UpdateData = () => import("@/components/UpdateData");
const InsertData = () => import("@/components/InsertData");
const CoverDesign = () => import("@/components/CoverDesign");
const LayoutDeisignTest = () => import("@/components/LayoutDeisignTest");
const CoverDesignTwo = () => import("@/components/CoverDesignTwo");
const CoverDesignSub = () => import("@/components/CoverDesignSub");
const CoverDesignA = () => import("@/components/CoverDesignA");
const CoverDesignVeraTic = () => import("@/components/CoverDesignVeraTic");
const getLable = () => import("@/components/getLable");
const getLableA = () => import("@/components/getLableA");
const getLableB = () => import("@/components/getLableB");
const getLableC = () => import("@/components/getLableC");
const PrintCer = () => import("@/components/PrintCer");
const PrintImgA = () => import("@/components/PrintImgA");
const PrintImgB = () => import("@/components/PrintImgB");
const PrintImgC = () => import("@/components/PrintImgC");
const PrintImgD = () => import("@/components/PrintImgD");
const PrintImgE = () => import("@/components/PrintImgE");
const getLableD = () => import("@/components/getLableD");
const PrintImgF = () => import("@/components/PrintImgF");
const PrintImgG = () => import("@/components/PrintImgG");
const PrintImgH = () => import("@/components/PrintImgH");
const PrintImgI = () => import("@/components/PrintImgI");
const PrintImgJ = () => import("@/components/PrintImgJ");
const PrintImgK = () => import("@/components/PrintImgK");
const PrintImgL = () => import("@/components/PrintImgL");
// 小标签
const LackIndexHave = () => import("@/components/LackIndexHave");

// 导出封面
const ExportCover = () => import("@/components/ExportCover");
// 微观小视界
const ExportMicroPhotos = () => import("@/components/ExportMicroPhotos");
// 三维图片
const ThreeImg = () => import("@/components/ThreeImg");
// 文件下载 网盘数据
const DownloadList = () => import("@/components/DownloadList");
// 下载图片
const DownloadImg = () => import("@/components/DownloadImg");
const CardLabelCertificate = () =>
  import("@/components/CardLabelCertificateNew");
const CardLabelCertificateNew = () =>
  import("@/components/CardLabelCertificate");
const TextLayout = () => import("@/components/TextLayout");
const CardLabelMaterialShop = () =>
  import("@/components/CardLabelMaterialShop");
const CardLabelMedicineText = () =>
  import("@/components/CardLabelMedicineText");
// 文档列表
const DocumentList = () => import("@/components/DocumentList");
const DocumentListKilala = () => import("@/components/DocumentListKilala");
// 表格式数据
const TableDataA = () => import("@/components/TableDataA");
const TableDataB = () => import("@/components/TableDataB");
// 库存
const TableDataInsectList = () => import("@/components/TableDataInsectList");
// 南开中学标签
const TableDataNankai = () => import("@/components/TableDataNankai");
// 二维码 门
const getLableNankaiCode = () => import("@/components/getLableNankaiCode");
// 模型 统称
const getLableNankai = () => import("@/components/getLableNankai");
// 超长标题  纯标题
const getLableNankaiLonglong = () =>
  import("@/components/getLableNankaiLonglong");
// 具体种
const getLableNankaiSpecies = () =>
  import("@/components/getLableNankaiSpecies");
// 纲
const LackIndexBuy = () => import("@/components/LackIndexBuy");
// 界
const LackIndex = () => import("@/components/LackIndex");
// 昆虫标签
const PrintImgM = () => import("@/components/PrintImgM");
// 昆虫标签  统称
const PrintImgN = () => import("@/components/PrintImgN");
// 特殊
const PriceRecordProject = () => import("@/components/PriceRecordProject");
// 浸渍标本 具体种
const CoverDesignVera = () => import("@/components/CoverDesignVera");
// 浸渍标本 科拉丁
const WangCun = () => import("@/components/WangCun");
// 浸渍标本 无拉丁
const WangCunList = () => import("@/components/WangCunList");
// 浸渍标本 超规格
const CreateCode = () => import("@/components/CreateCode");
// 浸渍标本 学生信息
const ZpageA = () => import("@/components/ZpageA");
// 浸渍标本 新中文名 1行
const ZpageE = () => import("@/components/ZpageE");
// 浸渍标本 新 中文名、拉丁名 2行
const ZpageF = () => import("@/components/ZpageF");

// 导航页面
const NavigationList = () => import("@/components/NavigationList");

// 河南自然博物馆
// 第二批标签
const getLableNankaiSpeciesLong = () =>
  import("@/components/getLableNankaiSpeciesLong");
// 河南自然博物馆标签
const getLableNankaiLongtitle = () =>
  import("@/components/getLableNankaiLongtitle");
const getLableNankaiLongtitleB = () =>
  import("@/components/getLableNankaiLongtitleB");

// 尧彩新
// 新目录
const NewIndex = () => import("@/components/NewIndex");
// 小程序分类
const ZpageB = () => import("@/components/ZpageB");
// 绘画作品商品
const ZpageC = () => import("@/components/ZpageC");
const ZpageD = () => import("@/components/ZpageD");

// 中昆
// 后台数据 表格式
const ZpageG = () => import("@/components/ZpageG");
// 报价表 表格式 原蝶
const ZpageH = () => import("@/components/ZpageH");
// 报价表 表格式 大型动物 检疫
const ZpageJ = () => import("@/components/ZpageJ");
const ZpageK = () => import("@/components/ZpageK");
// 3D模型报价表
const ZpageM = () => import("@/components/ZpageM");
// 科普道具报价表
const ZpageN = () => import("@/components/ZpageN");

// 尧彩新

// 播放视频
const ZpageL = () => import("@/components/ZpageL");

// 作废

const routes = [
  {
    path: "/",
    redirect: "/NavigationList",
  },
  {
    path: "/NavigationList",
    name: "NavigationList",
    component: NavigationList,
  },
  {
    path: "/ZpageN",
    name: "ZpageN",
    component: ZpageN,
  },
  {
    path: "/ZpageM",
    name: "ZpageM",
    component: ZpageM,
  },
  {
    path: "/ZpageL",
    name: "ZpageL",
    component: ZpageL,
  },
  {
    path: "/ZpageK",
    name: "ZpageK",
    component: ZpageK,
  },
  {
    path: "/ZpageJ",
    name: "ZpageJ",
    component: ZpageJ,
  },
  {
    path: "/ZpageH",
    name: "ZpageH",
    component: ZpageH,
  },
  {
    path: "/ZpageG",
    name: "ZpageG",
    component: ZpageG,
  },
  {
    path: "/ZpageF",
    name: "ZpageF",
    component: ZpageF,
  },
  {
    path: "/ZpageE",
    name: "ZpageE",
    component: ZpageE,
  },
  {
    path: "/ZpageD",
    name: "ZpageD",
    component: ZpageD,
  },
  {
    path: "/ZpageC",
    name: "ZpageC",
    component: ZpageC,
  },
  {
    path: "/ZpageB",
    name: "ZpageB",
    component: ZpageB,
  },
  {
    path: "/NewIndex",
    name: "NewIndex",
    component: NewIndex,
  },
  {
    path: "/ZpageA",
    name: "ZpageA",
    component: ZpageA,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/TableDataInsectList",
    name: "TableDataInsectList",
    component: TableDataInsectList,
  },
  {
    path: "/getLableNankaiSpeciesLong",
    name: "getLableNankaiSpeciesLong",
    component: getLableNankaiSpeciesLong,
  },
  {
    path: "/getLableNankaiCode",
    name: "getLableNankaiCode",
    component: getLableNankaiCode,
  },
  {
    path: "/getLableNankaiLonglong",
    name: "getLableNankaiLonglong",
    component: getLableNankaiLonglong,
  },
  {
    path: "/getLableNankaiLongtitle",
    name: "getLableNankaiLongtitle",
    component: getLableNankaiLongtitle,
  },
  {
    path: "/getLableNankaiLongtitleB",
    name: "getLableNankaiLongtitleB",
    component: getLableNankaiLongtitleB,
  },
  {
    path: "/TableDataNankai",
    name: "TableDataNankai",
    component: TableDataNankai,
  },
  {
    path: "/getLableNankaiSpecies",
    name: "getLableNankaiSpecies",
    component: getLableNankaiSpecies,
  },
  {
    path: "/getLableNankai",
    name: "getLableNankai",
    component: getLableNankai,
  },
  {
    path: "/TableDataA",
    name: "TableDataA",
    component: TableDataA,
  },
  {
    path: "/TableDataB",
    name: "TableDataB",
    component: TableDataB,
  },
  {
    path: "/DocumentListKilala",
    name: "DocumentListKilala",
    component: DocumentListKilala,
  },
  {
    path: "/DocumentList",
    name: "DocumentList",
    component: DocumentList,
  },

  {
    path: "/PrintImgN",
    name: "PrintImgN",
    component: PrintImgN,
  },
  {
    path: "/ExportCover",
    name: "ExportCover",
    component: ExportCover,
  },
  {
    path: "/ExportMicroPhotos",
    name: "ExportMicroPhotos",
    component: ExportMicroPhotos,
  },

  {
    path: "/ThreeImg",
    name: "ThreeImg",
    component: ThreeImg,
  },
  {
    path: "/DownloadList",
    name: "DownloadList",
    component: DownloadList,
  },
  {
    path: "/DownloadImg",
    name: "DownloadImg",
    component: DownloadImg,
  },
  {
    path: "/WangCunList",
    name: "WangCunList",
    component: WangCunList,
  },
  {
    path: "/WangCun",
    name: "WangCun",
    component: WangCun,
  },
  {
    path: "/PrintImgA",
    name: "PrintImgA",
    component: PrintImgA,
  },
  {
    path: "/PrintImgB",
    name: "PrintImgB",
    component: PrintImgB,
  },
  {
    path: "/PrintImgC",
    name: "PrintImgC",
    component: PrintImgC,
  },
  {
    path: "/PrintImgD",
    name: "PrintImgD",
    component: PrintImgD,
  },
  {
    path: "/PrintImgE",
    name: "PrintImgE",
    component: PrintImgE,
  },
  {
    path: "/PrintImgF",
    name: "PrintImgF",
    component: PrintImgF,
  },
  {
    path: "/PrintImgG",
    name: "PrintImgG",
    component: PrintImgG,
  },
  {
    path: "/PrintImgH",
    name: "PrintImgH",
    component: PrintImgH,
  },
  {
    path: "/PrintImgI",
    name: "PrintImgI",
    component: PrintImgI,
  },
  {
    path: "/PrintImgJ",
    name: "PrintImgJ",
    component: PrintImgJ,
  },
  {
    path: "/PrintImgK",
    name: "PrintImgK",
    component: PrintImgK,
  },
  {
    path: "/PrintImgL",
    name: "PrintImgL",
    component: PrintImgL,
  },
  {
    path: "/PrintImgM",
    name: "PrintImgM",
    component: PrintImgM,
  },
  {
    path: "/PriceRecordProject",
    name: "PriceRecordProject",
    component: PriceRecordProject,
  },
  {
    path: "/CoverDesignSub",
    name: "CoverDesignSub",
    component: CoverDesignSub,
  },
  {
    path: "/CoverDesignVera",
    name: "CoverDesignVera",
    component: CoverDesignVera,
  },
  {
    path: "/CoverDesignA",
    name: "CoverDesignA",
    component: CoverDesignA,
  },
  {
    path: "/CoverDesignVeraTic",
    name: "CoverDesignVeraTic",
    component: CoverDesignVeraTic,
  },
  {
    path: "/PrintCer",
    name: "PrintCer",
    component: PrintCer,
  },
  {
    path: "/CoverDesign",
    name: "CoverDesign",
    component: CoverDesign,
  },
  {
    path: "/getLable",
    name: "getLable",
    component: getLable,
  },
  {
    path: "/getLableA",
    name: "getLableA",
    component: getLableA,
  },
  {
    path: "/getLableB",
    name: "getLableB",
    component: getLableB,
  },
  {
    path: "/getLableC",
    name: "getLableC",
    component: getLableC,
  },
  {
    path: "/getLableD",
    name: "getLableD",
    component: getLableD,
  },
  {
    path: "/CoverDesignTwo",
    name: "CoverDesignTwo",
    component: CoverDesignTwo,
  },
  {
    path: "/TextLayout",
    name: "TextLayout",
    component: TextLayout,
  },
  {
    path: "/LayoutDeisignTest",
    name: "LayoutDeisignTest",
    component: LayoutDeisignTest,
  },
  {
    path: "/CardLabelCertificateNew",
    name: "CardLabelCertificateNew",
    component: CardLabelCertificateNew,
  },
  {
    path: "/CardLabelCertificate",
    name: "CardLabelCertificate",
    component: CardLabelCertificate,
  },
  {
    path: "/InsertData",
    name: "InsertData",
    component: InsertData,
  },

  {
    path: "/UpdateData",
    name: "UpdateData",
    component: UpdateData,
  },
  {
    path: "/DeletedData",
    name: "DeletedData",
    component: DeletedData,
  },
  {
    path: "/ThreeData",
    name: "ThreeData",
    component: ThreeData,
  },
  {
    path: "/CardLabelMaterialShop",
    name: "CardLabelMaterialShop",
    component: CardLabelMaterialShop,
  },
  {
    path: "/CardLabelMedicineText",
    name: "CardLabelMedicineText",
    component: CardLabelMedicineText,
  },
  {
    path: "/CardLabelMedicine",
    name: "CardLabelMedicine",
    component: CardLabelMedicine,
  },
  {
    path: "/PrintPage",
    name: "PrintPage",
    component: PrintPage,
  },
  {
    path: "/HandBook",
    name: "HandBook",
    component: HandBook,
  },
  {
    path: "/CardLabel",
    name: "CardLabel",
    component: CardLabel,
  },
  {
    path: "/CardLabelFish",
    name: "CardLabelFish",
    component: CardLabelFish,
  },
  {
    path: "/CardLabelBeast",
    name: "CardLabelBeast",
    component: CardLabelBeast,
  },
  {
    path: "/CardLabelButterfly",
    name: "CardLabelButterfly",
    component: CardLabelButterfly,
  },
  {
    path: "/InsectLable",
    name: "InsectLable",
    component: InsectLable,
  },
  {
    path: "/DetailPage",
    name: "DetailPage",
    component: DetailPage,
  },
  {
    path: "/LackIndexHave",
    name: "LackIndexHave",
    component: LackIndexHave,
  },
  {
    path: "/LackIndexBuy",
    name: "LackIndexBuy",
    component: LackIndexBuy,
  },
  {
    path: "/LackIndex",
    name: "LackIndex",
    component: LackIndex,
  },
  {
    path: "/CreateCode",
    name: "CreateCode",
    component: CreateCode,
  },
  {
    path: "/ImgGalleryNew",
    name: "ImgGalleryNew",
    component: ImgGalleryNew,
  },
  {
    path: "/ImgGalleryKilala",
    name: "ImgGalleryKilala",
    component: ImgGalleryKilala,
  },

  {
    path: "/Price",
    name: "Price",
    component: Price,
    children: [
      {
        path: "SelectSpecimen",
        name: "SelectSpecimen",
        component: SelectSpecimen,
      },
      {
        path: "LayoutDesign",
        name: "LayoutDesign",
        component: LayoutDesign,
      },
      {
        path: "LiquidSpecimen",
        name: "LiquidSpecimen",
        component: LiquidSpecimen,
      },
      {
        path: "InsectSpecies",
        name: "InsectSpecies",
        component: InsectSpecies,
      },
      {
        path: "BookList",
        name: "BookList",
        component: BookList,
      },
      {
        path: "PdfList",
        name: "PdfList",
        component: PdfList,
      },
      {
        path: "PdfDetail",
        name: "PdfDetail",
        component: PdfDetail,
      },
      {
        path: "UniversalData",
        name: "UniversalData",
        component: UniversalData,
      },

      {
        path: "UserData",
        name: "UserData",
        component: UserData,
      },
      {
        path: "GoodOrder",
        name: "GoodOrder",
        component: GoodOrder,
      },
      {
        path: "PriceRecordInsect",
        name: "PriceRecordInsect",
        component: PriceRecordInsect,
      },
      {
        path: "Stocks",
        name: "Stocks",
        component: Stocks,
      },
      {
        path: "Order",
        name: "Order",
        component: Order,
      },
      {
        path: "PriceRecord",
        name: "PriceRecord",
        component: PriceRecord,
      },
      {
        path: "ThreeList",
        name: "ThreeList",
        component: ThreeList,
      },
      {
        path: "ImgGallery",
        name: "ImgGallery",
        component: ImgGallery,
      },
      {
        path: "DownLoad",
        name: "DownLoad",
        component: DownLoad,
      },
      {
        path: "TagLable",
        name: "TagLable",
        component: TagLable,
      },
      {
        path: "MonoPhotos",
        name: "MonoPhotos",
        component: MonoPhotos,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
